<template>
  <div class="page-segments">
    <div class="row top-row">
      <div class="col-lg-12 tree-filters">
        <div class="row align-items-center">
          <div class="mb-3 col-lg-auto col">
            <search @search="handleSearch" size="lg"></search>
            <!--          <button class="btn btn-secondary p-2"><img src="../assets/icons/Icon-navigation-search.svg" alt=""></button>-->
          </div>
          <div class="mb-3 d-none col-auto">
            <input type="date" class="input-lg" />
          </div>
          <div class="mb-3 col-auto">
            <button
              class="btn btn-primary btn-sm"
              @click.prevent.stop="openSegmentModal"
            >
              <img
                class="hide-dark"
                src="../assets/icons/icon-navigation-add.svg"
                alt=""
              />
              <img
                class="hide-light"
                src="../assets/icons/icon-navigation-add-light.svg"
                alt=""
              />
              <span>{{ $t("message.segment") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 content-row d-flex boxshadow">
      <div class="p-0 col-xl-6 card flex-grow-1 rounded-end-0 boxshadow-0"
      :class="activeSegment ? 'd-none d-xl-block' : ''">
        <div class="card-header rounded-end-0 bgc-magenta c-200">
          <span class="overline text-sm text-white ">{{ $t("message.segment") }}</span>
        </div>
        <div
          class="card-body company-tree border-end position-relative"
          style="min-height: 150px"
        >
          <ul
            class="company-tree-content tree-ul no-tree"
          >
            <paginated
              style="min-height: 150px"
              :per-page="10"
              :request="segmentRequest"
              :total-rows="totalSegmentRows"
              @change="handleSegmentsPageChange"
            >
              <tree-item
                  :class="
                    activeSegment
                      ? activeSegment.id === tree.id
                        ? 'active'
                        : ''
                      : false
                  "
                  @setActiveTeam="setActiveSegment"
                  @getSegments="getSegments"
                  @setTree="handleReload"
                  @setLoaderTrue="segmentsLoaded = true"
                  @setLoaderFalse="segmentsLoaded = false"
                  @setActiveTeamManagers="setActiveSegmentManagers"
                  v-bind:key="tree"
                  v-for="tree in segments"
                  :stats-visible="false"
                  :content="tree"
              ></tree-item>
            </paginated>
          </ul>
<!--          <div-->
<!--            v-else-->
<!--            class="-->
<!--              position-absolute-->
<!--              top-50-->
<!--              start-50-->
<!--              translate-middle-->
<!--              container-spinner-->
<!--            "-->
<!--          >-->
<!--            <div class="spinner-border" role="status">-->
<!--              <span class="visually-hidden">{{ $t("message.loading") }}...</span>-->
<!--            </div>-->
<!--          </div>-->

          <p
            v-if="segments.length === 0 && segmentsLoaded"
            class="text-sentence"
          >
            {{ $t("message.no_data_available") }}.
          </p>
        </div>
      </div>
      <div
      v-if="activeSegment"
        class="p-0 col-xl-6 card flex-grow-1 rounded-start-0 boxshadow-0">
        <div class="card-header rounded-start-0 bgc-magenta c-50">
          <div
          :class="activeSegment ? '' : 'd-none d-xl-block'"
          class="card-header rounded-end-0 bgc-magenta c-50"
        >
          <span class="overline text-sm text-white">
            <span :class="activeSegment ? 'd-xl-none' : 'd-none'">
              <button
                class="btn btn-link p-0"
                @click.prevent="activeSegment = null"
              >
                &lt; <span class="text-sentence">{{ $t("message.goBack") }}</span>
              </button>
            </span>
          </span>
        </div>
          <span class="overline"></span>
        </div>
        <div class="card-body team-tree">
          <ul class="team">
            <li class="team-detail team-row">
              <div class="team-content d-flex align-items-center">
                <div class="team-name flex-grow-1">
                  <h5 class="mb-0">{{ activeSegment.title }}</h5>
                </div>
                <div class="actions d-flex">
                  <div class="tree-action graph border-start px-2 text-nowrap">
                    <img
                      src="../assets/icons/Icon-tree-user-profile.svg"
                      alt=""
                    />{{ activeSegment.zerglingcount }}
                  </div>
                </div>
              </div>
              <paginated
                @change="handlePageChange"
                :total-rows="totalRows"
                :per-page="10"
                :request="request"
              >
                <userlist
                  :show-vitamins="false"
                  :users="loadedUsers"
                ></userlist>
              </paginated>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Userlist from "@/views/components/Userlist";
import Search from "@/views/components/simple/Search";
import TreeItem from "@/views/components/TreeItem";
import Paginated from "@/views/components/simple/Paginated";
import SegmentModal from "@/views/components/modals/SegmentModal";

export default {
  components: {
    TreeItem,
    Search,
    Paginated,
    Userlist,
  },
  data() {
    return {
      segmentsLoaded: false,
      activeSegment: null,
      segmentRequest: null,
      segments: [],
      totalRows: 0,
      totalSegmentRows: 0,
      request: "",
      loadedUsers: [],
      search: "",
    };
  },
  created() {
    this.initSegments();
  },
  methods: {
    async initSegments() {
      await this.getSegments();
      this.mitt.emit("loadcomplete", { complete: true });
    },
    handleSearch(e) {
      this.search = e;
      this.getSegments();
    },
    openSegmentModal() {
      this.$store.dispatch("openModal", {
        component: SegmentModal,
        properties: {},
        options: {
          centered: true,
        },
        events: {
          submitSegment: this.handleReload,
        },
      });
    },
    async handleReload(segment) {
      // console.log('Reloading segments');
      await this.getSegments();
      console.log('updated:', segment);
      const index = this.segments.findIndex(item => item.id == segment.id);
      this.segments.splice(index, 1, segment);
      this.setActiveSegment(segment)
    },
    handlePageChange(users) {
      this.loadedUsers = users;
    },
    async getSegments() {
      let searchFilter = "";
      if (this.search) {
        searchFilter = "&title_contains=" + this.search;
      }
      const filter = "?visibility=true";
      this.segmentsLoaded = false;
      this.segmentRequest = "/swarms/segments" + filter + searchFilter;
      this.totalSegmentRows = (await this.axios.get('/swarms/count?issegment=true&visibility=true' + searchFilter)).data;
      // const get = await this.axios.get(
      //   "/swarms/segments" + filter + searchFilter
      // );
      // this.segments = get.data;
      // console.log(this.segments);
      // if(window.outerWidth > 1200){
      //   if (get.data.length) {
      //     this.setActiveSegment(this.segments[0]);
      //   }
      // }
      // this.segmentsLoaded = true;
    },
    handleSegmentsPageChange(data) {
      this.segments = data;
      if(window.outerWidth > 1200){
        if (data.length) {
          this.setActiveSegment(this.segments[0]);
        }
      }
      this.segmentsLoaded = true;
    },
    setActiveSegment(team) {
      this.activeSegment = team;
      this.totalRows = team.zerglingcount;
      this.getSegmentMembers();
    },
    setActiveSegmentManagers(team) {
      this.activeSegment = team;
      this.totalRows = team.overlordcount;
      this.getSegmentManagers();
    },
    async getSegmentManagers() {
      this.request = "";
      this.userListType = "managers";
      this.request =
        "/swarms/lingget/" +
        this.activeSegment.id +
        "?_swarms=" +
        this.activeSegment.id;
    },
    async getSegmentMembers() {
      this.request = "";
      this.userListType = "users";
      this.request =
        "/swarms/lingget/" +
        this.activeSegment.id +
        "?_member=" +
        this.activeSegment.id;
    },
  },
};
</script>

<style>
@media screen and (max-width: 992px) {
  .card {
    border-radius: 0px;
  }
}
</style>